<template>
  <div class="compare">
    <nav-bar-chat title="The Data" route="/" :share="userId" v-if="!hideNavBar"/>
    <div class="flex m-3 ml-4" 
      :class="{ 
        'mt-16': !hideNavBar
      }">
      <div v-if="isIncrease" class="unselected-tab p-1 px-3 text-center text-sm" @click="() => { switchTab('most') }" :class="{ 'select-tab': tab === 'most' }">Supported</div>
      <div v-if="isDecrease" class="unselected-tab p-1 px-3 text-center text-sm" @click="() => { switchTab('least') }" :class="{ 'select-tab': tab === 'least' }">Unsupported</div>
      <div class="unselected-tab p-1 px-3 text-center text-sm" @click="() => { switchTab('current') }" :class="{ 'select-tab': tab === 'current' }">U.S. Budget</div>
    </div>

    <div v-if="tab === 'current' && allCompareSpending && allCompareSpending.results && allCompareSpending.results.length">
      <div class="flex p-4 bg-gray-100 items-center">
        <div class="profile-pic mr-3">
          <capital-svg />
        </div>
        <div>
          How Congress currently allocates the annual budget.
        </div>
      </div>

      <div v-for="(spend, index) in percentages.results.filter(result => !result.upvoted && !result.downvoted)" v-bind:key="`spend-${index}`">
        <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
          <div>
            <div class="text-lg">
              <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
            </div>
          </div>

          <div class="text-gray-600 text-sm">
            <div class="font-semibold text-lg text-gray-600 mb-2">Current Spend: {{(spend.pct * 100).toFixed(0)}}%</div>
          </div>
        </div>
      </div>
    </div>
    
    <div v-if="tab === 'most' && yourCompareSpending && yourCompareSpending.results && yourCompareSpending.results.length">
      <div class="flex p-4 bg-gray-100 items-center">
        <div class="profile-pic mr-3">
          <capital-svg />
        </div>
        <div>
          % of survey respondents who want Congress to spend more on each budget category
        </div>
      </div>

      <div v-for="(spend, index) in percentages.results.filter(result => result.titleShort).sort((a, b) => { return a.incrementCount - b.incrementCount}).reverse()" v-bind:key="`options-increase-${index}`">
        <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
          <div>
            <div class="text-lg">
              <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
            </div>
          </div>

          <div class="text-gray-600 text-sm">
            <div class="font-semibold text-lg text-gray-600 mb-2"><span class="text-green-500">{{(spend.incrementCount * 100).toFixed(0)}}% of People Increased</span></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="tab === 'least' && yourCompareSpending && yourCompareSpending.results && yourCompareSpending.results.length">
      <div class="flex p-4 bg-gray-100 items-center">
        <div class="profile-pic mr-3">
          <capital-svg />
        </div>
        <div>
          % of respondents who want Congress to spend less on each budget category
        </div>
      </div>
      
      <div v-for="(spend, index) in percentages.results.filter(result => result.titleShort).sort((a, b) => { return a.decrementCount - b.decrementCount}).reverse()" v-bind:key="`options-increase-${index}`">
        <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
          <div>
            <div class="text-lg">
              <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
            </div>
          </div>

          <div class="text-gray-600 text-sm">
            <div class="font-semibold text-lg text-gray-600 mb-2"><span class="text-red-500">{{(spend.decrementCount * 100).toFixed(0)}}% of People Decreased</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="border rounded m-4 p-4 bg-gray-100">
      Contact us info@wheretospend.org
      <br />
      Source: <a class="text-blue-400" target="_blank" href="https://www.usaspending.gov/">USAspending.gov</a>
    </div>
  </div>
</template>

<script>
import NavBarChat from '@/components/NavBarChat'
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { getSpendingCost } from '@/services/services'
import { dbStore } from '@/firebase'
import { options } from '@/mock-data/options'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import CapitalSvg from '@/svgs/CapitalSvg.vue'

export default {
  name: 'Compare',
  components: {
    NavBarChat,
    CapitalSvg
  },
  props: ['hideNavBar'],
  data() {
    return {
      userId: '',
      tab: 'most',
      percentages: [],
      allCompareSpending: [],
      yourCompareSpending: [],
      increaseTags: [],
      decreaseTags: [],
      countIncrementTags: [],
      countDecrementTags: [],
      isIncrease: 0,
      isDecrease: 0,
    }
  },
  watch: {
    '$store.state.tags': async function() {
      const userId = this.userId = await AsyncLocalStorage.getItem('@userid')
      // this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
      // this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))
      this.currentSpending(true)
    },
    '$store.state.tagsDecrease': async function() {
      const userId = this.userId = await AsyncLocalStorage.getItem('@userid')
      // this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
      // this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))
      this.currentSpending(true)
    }
  },
  async mounted() {
    const userId =  this.userId = await AsyncLocalStorage.getItem('@userid')
    // this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
    // this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))

    this.currentSpending()
    this.getSpendingCost()
  },
  methods: {
    switchTab(tab) {
      this.tab = tab
      console.log(this.tab, 'tab')
    },
    getSpendingCost,
    optionsList() {
      return Object.keys(this.$store.getters.options).filter(option => {
        if (options[option].selected === false) {
          return options[option];
        }
      });
    },
    formatNumberCharacter(x) {
      if(isNaN(x)) return x;

      if(x < 9999) {
        return x;
      }

      if(x < 1000000) {
        return Math.round(x/1000) + "K";
      }
      if( x < 10000000) {
        return (x/1000000).toFixed(2) + "M";
      }

      if(x < 1000000000) {
        return Math.round((x/1000000)) + "M";
      }

      if(x < 1000000000000) {
        return Math.round((x/1000000000)) + "B";
      }

       if(x < 1000000000000000) {
        return Math.round((x/1000000000000)) + "T";
      }

      return "Too Much Money";
    },
    greaterThanZero(pct) {
      return (Math.round(Math.floor(pct)) < 0)
    },
    async currentSpending(wipe) {
      console.log('current')
      this.$store.dispatch('currentSpending')
      this.percentages = this.$store.getters.currentSpending
      
      const optionsReduce = options;
      const totalBudget = this.percentages.total
      this.percentages.results.map(percentage => { // create util
        return Object.keys(optionsReduce)
          .forEach(option => {
            if (percentage.name === optionsReduce[option].title) {
              percentage.titleShort = optionsReduce[option].titleShort
              percentage.cardid = optionsReduce[option].cardid
              percentage.pct = percentage.amount/totalBudget
              return percentage
            }
          })
      })

      console.log(this.percentages)

      const increment = await dbStore.collection('increment').get() 
      const decrement = await dbStore.collection('decrement').get()
      const incrementAll = await dbStore.collection('increment').doc('all').get()
      const decrementAll = await dbStore.collection('decrement').doc('all').get()
      
      const incrementAllCount = this.isIncrease = incrementAll.data()?.count
      const decrementAllCount = this.isDecrease = decrementAll.data()?.count

      setTimeout(() => {
        (!this.isIncrease && !this.isDecrease) 
          ? this.switchTab('current') 
          : this.switchTab('most')
      }, 1000)

      //get title and count
      increment.docs.forEach(doc => {
        if (doc.id !== 'all') {
          this.countIncrementTags.push({ 
            title: doc.id, 
            data: doc.data() 
          })
        }

        this.percentages.results.map(percentage => {
          percentage.incrementCount = 0
          return this.countIncrementTags
            .forEach(tag => {
              if (percentage.cardid === tag.title) {
                percentage.incrementCount = (tag.data.count / incrementAllCount)
                return percentage
              }
            })
        })
      })

      decrement.docs.forEach(doc => {
        if (doc.id !== 'all') {
          this.countDecrementTags.push({ 
            title: doc.id, 
            data: doc.data() 
          })
        }

        this.percentages.results.map(percentage => {
          percentage.decrementCount = 0
          return this.countDecrementTags
            .forEach(tag => {
              if (percentage.cardid === tag.title) {
                percentage.decrementCount = (tag.data.count / decrementAllCount)
                return percentage
              }
            })
          })
      })

      this.yourCompareSpending = this.percentages

      if (this.increaseTags) {
        this.percentages.results.map(percent => {
          Object.keys(this.increaseTags).forEach((tag) => {
            if (percent.cardid === this.increaseTags[tag]?.option.cardid) {
              percent.upvoted = true
            }
          })
        })
      }

      if (this.decreaseTags) {
        this.percentages.results.map(percent => {
          Object.keys(this.decreaseTags).forEach((tag) => {
            if (percent.cardid === this.decreaseTags[tag]?.option.cardid) {
              percent.downvoted = true
            }
          })
        })
      }

      this.allCompareSpending = this.percentages
    }
    
  }
  
}
</script>

<style scoped lang="scss">
  .bg-green-gradient {
    background: linear-gradient(142deg, rgba(209,250,229,0.7) 51%, rgba(170,241,255,0.5) 100%);
  }
  .bg-red-gradient {
    background: linear-gradient(142deg, rgba(254,226,226, 0.7) 15%, rgba(199,161,184,0.5) 100%);
  }
  .unselected-tab {
    border: 2px solid transparent;
  }
  .select-tab {
    border: 2px solid black;
    border-radius: 50px;
  }
  .profile-pic {
    border: 1px solid #bbb;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    svg {
      height: 35px;
      width: 35px;
    }
  }
  .dont-go-back {
    overscroll-behavior-x: none;
  }
  .compare {
    height: 100vh;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    overflow-y: scroll;

    .compare-spend-box {}
  }
  .pill {
    border-radius: 5px;
  }
  .progress-bar {
    height: 20px;
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 4px;
    background: #fff;
  }
  .percentage {
    height: 18px;
    border-radius: 4px;
  }
  .percentage-left {
    height: 18px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .percentage-right {
    height: 18px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .select-budget-container {
    .select-budget {
      &:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      &:last-child {
        border-bottom: 1px solid #bbb;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      border-top: 1px solid #bbb;
      border-right: 1px solid #bbb;
      border-left: 1px solid #bbb;

      .help-underline {
        border-left: 4px solid #7eb643;
        padding-left: 4px;
      }
    }
  }
</style>