export const chat = {
	increaseBudget: {
		started: true,
		completed: false,
		loading: false,
		goTo: ['decreaseBudget']
	},
	decreaseBudget: {
		started: false,
		completed: false,
		loading: false,
		goTo: ['compareBudget', 'shareBudget']
	},
	otherBudget: {
		started: false,
		completed: false,
		loading: false
	},
	compareBudget: {
		started: false,
		completed: false,
		loading: false
	},
	shareBudget: {
		started: false,
		completed: false,
		loading: false,
		shareActions: {
			congress: {
				goTo: ['addressBudget']
			},
			tweet: {
				goTo: ''
			},
			socialMedia: {
				goTo: ''
			}
		}
	},
	addressBudget: {
		started: false,
		completed: false,
		loading: false,
		goTo: 'congressBudget'
	},
	congressBudget: {
		started: false,
		completed: false,
		loading: false
	}
}