<template>
	<div class="flex mb-8" :class="{ 'justify-end': myPost }">
		<div class="profile-pic mr-3" v-if="!myPost">
			<capital-svg v-if="!noIcon"/>
		</div>
		<div class="app-message-container" v-if="img && !myPost">
			<div class="app-message py-4 px-4">
				<slot name="talk"></slot>
			</div>
			<slot name="image"></slot>
		</div>

		<div class="app-message-container" v-if="!img && !myPost">
			<div class="app-message-no-img py-4 px-4">
				<slot name="talk"></slot>
			</div>
		</div>

		<div class="user-message-container" v-if="myPost">
			<div class="user-message py-4 px-4">
				<slot name="talk"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	import CapitalSvg from '@/svgs/CapitalSvg.vue'

  export default {
    name: 'ChatBubble',
		components: {
			CapitalSvg
		},
		props: {
			img: {
				type: Boolean
			},
			myPost: {
				type: Boolean
			},
			noIcon: {
				type: Boolean
			}
		}
  }
</script>

<style scoped lang="scss">
	.profile-pic {
		border: 1px solid #bbb;
		border-radius: 50%;
		height: 35px;
		width: 35px;
		svg {
			height: 35px;
			width: 35px;
		}
	}

	.app-message-container {
		width: 100%;

		.app-message {
			border: 1px solid #eee;
			background: #eee;
			border-top-right-radius: 25px;
			border-top-left-radius: 25px;
		}

		.app-message-no-img {
			border: 1px solid #eee;
			background: #eee;
			border-radius: 25px;
		}

		img {
			border: 1px solid #eee;
			border-bottom-right-radius: 25px;
			border-bottom-left-radius: 25px;
			width: 100%;
		}

		img.override-curve {
			border-radius: 0px;
		}
	}

	.user-message-container {
    overflow: auto;

		.user-message {
			width: 80%;
			float: right;
			border-radius: 25px;
			color: #fff;
			background: #0000ff;
		}
  }
</style>
