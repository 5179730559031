






















import Vue from 'vue';
import WeirdBubble from '@/assets/logo-title.svg'

export default Vue.extend({
  name: 'NavBar',
  props: ["title", "version"],
  data() {
    return {
      WeirdBubble
    }
  }
});
