import Vue from 'vue'
import Vuex from 'vuex'
import BudgetModule from './budget.js'
import UserModule from './user.js'
import Chat from './chat.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Chat,
    BudgetModule,
    UserModule
  }
})
