<template>
  <div class="compare">
    <div class="flex m-3 ml-4">
      <div v-if="this.increaseTags || this.decreaseTags" class="unselected-tab p-1 px-6 text-center" @click="() => { switchTab('your') }" :class="{ 'select-tab': tab === 'your' }">You</div>
      <div v-if="this.increaseTags || this.decreaseTags" class="unselected-tab p-1 px-6 text-center" @click="() => { switchTab('others') }" :class="{ 'select-tab': tab === 'others' }">Others</div>
      <div class="unselected-tab p-1 px-6 text-center" @click="() => { switchTab('all') }" :class="{ 'select-tab': tab === 'all' }">Congress</div>
    </div>

    <div v-if="tab === 'all' && allCompareSpending && allCompareSpending.results && allCompareSpending.results.length" class="mb-32">
       <div class="m-4 rounded flex p-4 bg-gray-100 items-center">
          <div class="profile-pic mr-3">
            <capital-svg />
          </div>
          <div>
            % of Congressional spend for all budgets
          </div>
        </div>
      <div class="border rounded m-4" v-if="percentages.results.filter(result => result.upvoted).length">
        <div class="px-4 py-2 text-lg font-semibold bg-green-gradient">You want them to spend more than:</div>
        <div v-for="(spend, index) in percentages.results.filter(result => result.upvoted).sort((a, b) => { return a.pct - b.pct}).reverse()" v-bind:key="`options-increase-${index}`">
          <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
            <div>
              <div class="text-lg">
                <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
              </div>
            </div>

            <div class="text-gray-600 mb-2">{{(spend.pct * 100).toFixed(0)}}% annually</div>
          </div>
        </div>
      </div>

      <div class="border rounded m-4" v-if="percentages.results.filter(result => result.downvoted).length">
        <div class="px-4 py-2 text-lg font-semibold bg-red-gradient">You want them to spend less than:</div>
        <div v-for="(spend, index) in percentages.results.filter(result => result.downvoted).sort((a, b) => { return a.pct - b.pct}).reverse()" v-bind:key="`options-increase-${index}`">
          <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
            <div>
              <div class="text-lg">
                <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
              </div>
            </div>

            <div class="text-gray-600 mb-2">{{(spend.pct * 100).toFixed(0)}}% annually</div>
          </div>
        </div>
      </div>

      <div class="border rounded m-4">
        <div class="px-4 py-2 text-lg font-semibold bg-gray-100">All</div>
        <div v-for="(spend, index) in percentages.results.filter(result => !result.upvoted && !result.downvoted)" v-bind:key="`spend-${index}`">
          <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
            <div>
              <div class="text-lg">
                <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
              </div>
            </div>

            <div class="text-gray-600 text-sm">
              <div class="font-semibold text-lg text-gray-600 mb-2">Current Spend: {{(spend.pct * 100).toFixed(0)}}%               
                <div class="inline-block mb-2">
                  <div class="pill text-xs leading-3 font-semibold bg-green-200 text-green-800 px-3 py-1 inline-block" 
                    v-if="spend.upvoted">You Increased</div>
                  <div class="pill text-xs leading-3 font-semibold bg-red-200 text-red-800 px-3 py-1 inline-block" 
                    v-if="spend.downvoted">You Decreased</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="(this.increaseTags || this.decreaseTags) && tab === 'others' && yourCompareSpending && yourCompareSpending.results && yourCompareSpending.results.length" class="mb-32">
       <div class="m-4 rounded flex p-4 bg-gray-100 items-center">
          <div class="profile-pic mr-3">
            <capital-svg />
          </div>
          <div>
           % of respondents who chose like you
          </div>
        </div>

      <div class="border rounded m-4" v-if="percentages.results.filter(result => result.upvoted).length">
        <div class="px-4 py-2 text-lg font-semibold bg-green-gradient">Spend More</div>
        <div v-for="(spend, index) in percentages.results.filter(result => result.upvoted).sort((a, b) => { return a.pct - b.pct}).reverse()" v-bind:key="`options-increase-${index}`">
          <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
            <div>
              <div class="text-lg">
                <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
              </div>
            </div>

            <div class="text-gray-600 mb-2">{{(spend.incrementCount * 100).toFixed(0)}}% agree</div>
          </div>
        </div>
      </div>

      <div class="border rounded m-4" v-if="percentages.results.filter(result => result.downvoted).length">
        <div class="px-4 py-2 text-lg font-semibold bg-red-gradient">Spend Less</div>
        <div v-for="(spend, index) in percentages.results.filter(result => result.downvoted).sort((a, b) => { return a.pct - b.pct}).reverse()" v-bind:key="`options-increase-${index}`">
          <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
            <div>
              <div class="text-lg">
                <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
              </div>
            </div>

            <div class="text-gray-600 mb-2">{{(spend.decrementCount * 100).toFixed(0)}}% agree</div>
          </div>
        </div>
      </div>
    </div>
    
    <div v-if="(this.increaseTags || this.decreaseTags) && tab === 'your' && yourCompareSpending && yourCompareSpending.results && yourCompareSpending.results.length">
       <div class="m-4 rounded flex p-4 bg-gray-100 items-center">
          <div class="profile-pic mr-3">
            <capital-svg />
          </div>
          <div>
           What you chose compared to current U.S. spending
          </div>
        </div>
        <MySpend noHeader/>
    </div>
  </div>
</template>

<script>
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { getSpendingCost } from '@/services/services'
import { dbStore } from '@/firebase'
import { options } from '@/mock-data/options'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import CapitalSvg from '@/svgs/CapitalSvg.vue'
import MySpend from '@/components/flow/MySpend.vue'

import setAnnualBudgetCost from '@/utils/setAnnualBudgetCost'
import { calculateRelateToOthers, calculatePercentageCount } from '@/utils/calculateOthers'
import setVote from '@/utils/setVote'
import formatNumberCharacter from '@/utils/formatNumberCharacter'

export default {
  name: 'ShowCompare',
  components: {
    CapitalSvg,
    MySpend
  },
  props: ['hideNavBar'],
  data() {
    return {
      userId: '',
      tab: 'all',
      percentages: [],
      allCompareSpending: [],
      yourCompareSpending: [],
      increaseTags: [],
      decreaseTags: [],
      countIncrementTags: [],
      countDecrementTags: [],
    }
  },
  watch: {
    '$store.state.increaseTags': async function() {
      const userId = this.userId = await AsyncLocalStorage.getItem('@userid')
      this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
      this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))
      this.currentSpending(true)
      this.getSpendingCost()
    },
    '$store.state.decreaseTags': async function() {
      const userId = this.userId = await AsyncLocalStorage.getItem('@userid')
      this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
      this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))
      this.currentSpending(true)
      this.getSpendingCost()
    },
  },
  async mounted() {
    const userId =  this.userId = await AsyncLocalStorage.getItem('@userid')
    this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
    this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))

    this.tab = (!this.increaseTags || !this.decreaseTags) ? 'all' : 'your'
    this.currentSpending()
    this.getSpendingCost()
  },
  methods: {
    switchTab(tab) {
      this.tab = tab
    },
    getSpendingCost,
    formatNumberCharacter,
    greaterThanZero(pct) {
      return (Math.round(Math.floor(pct)) < 0)
    },
    async currentSpending(wipe) {
      this.$store.dispatch('currentSpending')
      this.percentages = this.$store.getters.currentSpending

      const optionsReduce = options
      const totalBudget = this.percentages.total
      // pass in cardid, title, shortTitle for cross reference
      this.percentages.results = setAnnualBudgetCost(this.percentages.results, optionsReduce, totalBudget)

      const increment = await dbStore.collection('increment').get()
      const decrement = await dbStore.collection('decrement').get()
      const incrementAll = await dbStore.collection('increment').doc('all').get()
      const decrementAll = await dbStore.collection('decrement').doc('all').get()
      
      const incrementAllCount = incrementAll.data()?.count
      const decrementAllCount = decrementAll.data()?.count

      //get increase count
      const countIncreaseTags = calculateRelateToOthers(increment.docs, this.countIncrementTags)
      this.percentages.results = calculatePercentageCount(this.percentages.results, countIncreaseTags, incrementAllCount, 'increment')

      //get decrease count
      const countDecreaseTags = calculateRelateToOthers(decrement.docs, this.countDecrementTags)
      this.percentages.results = calculatePercentageCount(this.percentages.results, countDecreaseTags, decrementAllCount, 'decrement')

      if (this.increaseTags && Object.keys(this.increaseTags).length) {
        this.percentages.results = setVote(this.percentages.results, this.increaseTags, 'upvoted')
      }

      if (this.decreaseTags && Object.keys(this.decreaseTags).length) {
        this.percentages.results = setVote(this.percentages.results, this.decreaseTags, 'downvoted')
      }

      this.yourCompareSpending = this.percentages
      this.allCompareSpending = this.percentages
    }
    
  }
  
}
</script>

<style scoped lang="scss">
  .back-icon-space {
    height: 35px;
  }
  .bg-green-gradient {
    background: linear-gradient(142deg, rgba(209,250,229,0.7) 51%, rgba(170,241,255,0.5) 100%);
  }
  .bg-red-gradient {
    background: linear-gradient(142deg, rgba(254,226,226, 0.7) 15%, rgba(199,161,184,0.5) 100%);
  }
  .unselected-tab {
    border: 2px solid transparent;
  }
  .select-tab {
    border: 2px solid black;
    border-radius: 50px;
  }
  .profile-pic {
    border: 1px solid #bbb;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    svg {
      height: 35px;
      width: 35px;
    }
  }
  .dont-go-back {
    overscroll-behavior-x: none;
  }
  .compare {
    height: 100vh;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    overflow-y: scroll;

    .compare-spend-box {
      // border: 1px solid #bbb;
      // border-radius: 8px;
      // background: #f8f8f8;
    }
  }
  .pill {
    border-radius: 5px;
  }
  .progress-bar {
    height: 20px;
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 4px;
    background: #fff;
  }
  .percentage {
    height: 18px;
    border-radius: 4px;
  }
  .percentage-left {
    height: 18px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .percentage-right {
    height: 18px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
</style>