const currentDefault = {
  "currentSpending": [{
          "title": "Medicare",
          "titleShort": "Medicare",
          "pct": 14.64
      },
      {
          "title": "Social Security",
          "titleShort": "Social Security",
          "pct": 23.49
      },
      {
          "title": "National Defense",
          "titleShort": "National Defense",
          "pct": 15.46
      },
      {
          "title": "Health",
          "titleShort": "Health",
          "pct": 13.15
      },
      {
          "title": "Income Security",
          "titleShort": "Income Security",
          "pct": 11.59
      },
      {
          "title": "General Government",
          "titleShort": "General Government",
          "pct": 0.44
      },
      {
          "title": "Veterans Benefits and Services",
          "titleShort": "Veterans",
          "pct": 4.5
      },
      {
          "title": "International Affairs",
          "titleShort": "International Affairs",
          "pct": 1.19
      },
      {
          "title": "Education, Training, Employment, and Social Services",
          "titleShort": "Education / Social Services",
          "pct": 3.04
      },
      {
          "title": "Transportation",
          "titleShort": "Transportation",
          "pct": 2.16
      },
      {
          "title": "Community and Regional Development",
          "titleShort": "Community / Regional",
          "pct": 0.66
      },
      {
          "title": "Natural Resources and Environment",
          "titleShort": "Environment",
          "pct": 0.81
      },
      {
          "title": "Administration of Justice",
          "titleShort": "Administration of Justice",
          "pct": 1.48
      },
      {
          "title": "Commerce and Housing Credit",
          "titleShort": "Commerce / Housing",
          "pct": -0.59
      },
      {
          "title": "General Science, Space, and Technology",
          "titleShort": "Science / Technology",
          "pct": 0.73
      },
      {
          "title": "Agriculture",
          "titleShort": "Agriculture",
          "pct": 0.9
      },
      {
          "title": "Energy",
          "titleShort": "Energy",
          "pct": 0.11
      }
  ]
}

export {
  currentDefault
}
