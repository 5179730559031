import { getSpendingCost } from '@/services/services'
import { options } from '@/mock-data/options'
import { chat } from '@/mock-data/chat'
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { db, dbStore } from '@/firebase'
import firebase from 'firebase'
import setBudgetFlag from '@/utils/setBudgetFlag'
import getIncreaseFlag from '@/utils/getIncreaseFlag'
import getDecreaseFlag from '@/utils/getDecreaseFlag'
import getUserId from '@/utils/getUserId'
import setIncreaseTags from '@/utils/setIncreaseTags'
import setDecreaseTags from '@/utils/setDecreaseTags'

const calculateTags = async (typeFlag, tag, count) => {
	const incrementOrDecrement = (typeFlag === 'increase') 
		? 'increment' 
		: 'decrement'
	
	await dbStore.collection(incrementOrDecrement)
		.doc(tag)
		.update({ 
			count: firebase.firestore.FieldValue.increment(count),
			time: firebase.firestore.FieldValue.serverTimestamp()
		})
		.catch(async () => {
			// The document probably doesn't exist.
			await dbStore.collection(incrementOrDecrement)
				.doc(tag)
				.set({
					count: 1, // revist
					time: firebase.firestore.FieldValue.serverTimestamp()
				})
		})
}
const getIncreaseTag = async (userId, tag) => {
	await dbStore.collection('increaseTags')
		.doc(userId)
		.update({ 
			increaseTags: tag
		})
		.catch(async () => {
			// The document probably doesn't exist.
			await dbStore.collection('increaseTags')
				.doc(userId)
				.set({
					increaseTags: tag
				})
			})
}
const getDecreaseTag = async (userId, tag) => {
	await dbStore.collection('decreaseTags')
		.doc(userId)
		.update({ 
			decreaseTags: tag
		})
		.catch(async () => {
			// The document probably doesn't exist.
			await dbStore.collection('decreaseTags')
				.doc(userId)
				.set({
					decreaseTags: tag
				})
			})
}
const updateIncreaseDecreaseTags = async (commit, typeFlag, userId, allSelectedBudgets) => {
	if (typeFlag === 'increase') {
		//TODO: Remove tag on unselect
		await setIncreaseTags(userId, allSelectedBudgets)
		await getIncreaseTag(userId, allSelectedBudgets)
		commit('tags', allSelectedBudgets)
	} else {
		//TODO: Remove tag on unselect
		await setDecreaseTags(userId, allSelectedBudgets)
		await getDecreaseTag(userId, allSelectedBudgets)
		commit('tagsDecrease', allSelectedBudgets)
	}
}

const Chat = {
	state: {
		budgets: options,
		chat,
		// selectedBudget: [],
		// currentSpending: currentDefault,
		increaseTags: [],
		decreaseTags: [],
		// address: ''
	},
	getters: {
		budgets: (state) => state.budgets,
		chat: (state) => state.chat,
		// currentSpending: (state) => state.currentSpending,
		increaseTags: (state) => state.tags,
		decreaseTags: (state) => state.tagsDecrease,
		// address: (state) => state.address
	},
	mutations: {
		chat(state, prop) {
			state.chat[prop].started = true
		},
		tags(state, tags) {
      state.increaseTags = tags
    },
    tagsDecrease(state, tagsDecrease) {
      state.decreaseTags = tagsDecrease
    },
		budgets(state, budgets) {
			state.budgets = budgets
		}
	},
	actions: {
		async budgetItem({ commit, dispatch }, budgetItem) {
			const userId = await getUserId()
			const typeFlag = budgetItem.typeFlag
      const allIncreaseFlag = await getIncreaseFlag(userId)
      const allDecreaseFlag = await getDecreaseFlag(userId)
			const flagIncreaseResult = await setBudgetFlag(userId, typeFlag, allIncreaseFlag)
			const flagDecreaseResult = await setBudgetFlag(userId, typeFlag, allDecreaseFlag)
			const allSelectedBudgets = budgetItem.budgets
			const incrementOrDecrement = (typeFlag === 'increase') ? 'increment' : 'decrement'

			if (flagIncreaseResult && incrementOrDecrement === 'increment') dispatch('allBudget', incrementOrDecrement)
			if (flagDecreaseResult && incrementOrDecrement === 'decrement') dispatch('allBudget', incrementOrDecrement)
			await calculateTags(typeFlag, budgetItem.item, budgetItem.count)
			await updateIncreaseDecreaseTags(commit, typeFlag, userId, allSelectedBudgets)
		},
		async subtractBudgetItem({ commit }, budgetItem) {
			const userId = await getUserId()
			const allSelectedBudgets = budgetItem.budgets
			const typeFlag = budgetItem.typeFlag

			await calculateTags(typeFlag, budgetItem.item, budgetItem.count)
			await updateIncreaseDecreaseTags(commit, typeFlag, userId, allSelectedBudgets)
		},
		async allBudget(_, increaseOrDecrease) {
			if (!increaseOrDecrease) return false
      await dbStore.collection(increaseOrDecrease)
				.doc('all')
				.update({
					count: firebase.firestore.FieldValue.increment(1),
					time: firebase.firestore.FieldValue.serverTimestamp()
				})
				.catch(async () => {
					// The document probably doesn't exist.
					await dbStore.collection(increaseOrDecrease)
						.doc('all')
						.set({
							count: 1, // revist
							time: firebase.firestore.FieldValue.serverTimestamp()
						})
				})
    },
	}
}

export default Chat