<template>
  <div class="nav-bar-chat shadow-sm p-2 flex items-center">
    <div class="flex w-full items-center">
      <router-link v-if="!removeBack" :to="route || '/'">
        <div class="back-icon">
          <back-svg />
        </div>
      </router-link>
      <div class="text-center ml-2 font-semibold text-lg">{{title || 'Where To Spend'}}</div>
    </div>
    <div v-if="share || tweet" class="flex">
      <div v-if="tweet" class="px-4 py-1 border rounded-md mr-4 bg-blue-100 font-semibold">Tweet</div>
      <router-link to="/">
        <div class="whitespace-nowrap px-4 py-1 rounded-md mx-4 bg-gray-100 font-semibold bg-blue-500 text-white">Get started</div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import BackSvg from '@/svgs/BackSvg.vue'

  export default {
    name: 'NavBarChat',
    components: {
      BackSvg
    },
    props: ["title", "route", "share", "removeBack", "tweet"]
  }
</script>

<style scoped>
  .back-icon svg {
    height: 35px;
  }
  .nav-bar-chat {
    background: #fff;
    position: fixed;
    width: 100%;
    max-width: 700px;
    z-index: 10;
    top: 0;
  }
</style>
