<template>
  <div>
    <div class="home v-2 relative" v-if="version === 1">
      <nav-bar version="1" />
      <div class="center-me">
        <div class="homepage p-4 mt-6 m-auto">
          <div class="money-logo-container mb-10">
            <div class="money-logo m-auto grid grid-cols-3 justify-center">
              <div>
                <div class="icon-alt money-svg circle-svg bg-yellow-200"><money-svg/></div>
                <div class="icon-alt-2 capital-svg circle-svg bg-green-400"><capital-svg/></div>
              </div>
              <img class="justify-self-center" src="https://assets.codepen.io/86959/Money" />
              <div class="icon-alt-3 compare-svg circle-svg bg-red-400 justify-self-end"><compare-svg/></div>
            </div>
          </div>
          
          <div class="text-center">
            <div class="text-3xl mb-4 font-bold">Where should congress spend money?</div>
            <div class="text-sm text-gray-500">
              <div>Select, compare, and share the budgets that matter most to you.</div>
            </div>
          </div>
        </div>
      
        <div class="cta absolut w-full">
          <router-link to="/chat" v-if="!hideButton">
            <div class="cta-start text-center m-auto p-2 px-2 bg-blue-500 text-white font-semibold">
              Get started
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import CapitalSvg from '@/svgs/CapitalSvg'
import CompareSvg from '@/svgs/CompareSvg'
import MoneySvg from '@/svgs/MoneySvg'
import 'animate.css'
import WeirdBubble from '@/assets/WeirdBubble.svg'

export default {
  name: 'Home',
  components: {
    NavBar,
    CapitalSvg,
    CompareSvg,
    MoneySvg
  },
  props: {
    hideButton: {
      type: Boolean
    }
  },
  data() {
    return {
      version: 1,
      WeirdBubble
    };
  },
  mounted() {
    // this.$store.dispatch('getBudgetItems')
  },
  methods: {
    fullScreen() {
      const element = document.documentElement
      if(element.requestFullscreen) {
        element.requestFullscreen();
      } else if(element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if(element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if(element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import url('https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css');

  .home {
    height: 604px;
    width: 100%;
    margin: 0 auto;
    
    .center-me {
      display: grid;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 60px);
      
      .homepage {
        max-width: 350px;
      }
    }
  }

  .v-2 {
    .money-logo {
      width: 215px;
      text-align: center;

      img {
        max-width: 200px;
        width: 200px;
        height: 178px;
      }
      
      .icon {
        position: relative;
        z-index: 100;
      }
      
      .icon-alt {
        position: relative;
        z-index: 100;
        height: 80px;
        width: 80px;
      }
      
      .icon-alt-2 {
        position: relative;
        z-index: 100;
        height: 60px;
        width: 60px;
      }

      .icon-alt-3 {
        position: relative;
        z-index: 100;
        height: 70px;
        width: 70px;
      }
      
      .circle-svg {
        border-radius: 50%;
        padding: 10px;
      }
      
      .money-svg {
        top: -20px;
        left: -70px;
        animation: mover 2s infinite  alternate;
      }
      
      .capital-svg {
        top: 70px;
        left: 25px;
        animation: mover 2s infinite  alternate;
        animation-delay: 1s;
      }
      
      .compare-svg {
        top: 30px;
        left: 70px;
        animation: mover 2s infinite  alternate;
        animation-delay: 0.5s;
      }
    }

    @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
    }
    @keyframes mover {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }

    
    .cta {
      bottom: 35px;
      margin-bottom: 15px;
      .cta-start {
        border: 1px solid #bbb;
        width: 200px;
        border-radius: 25px;
      }
    }
  }
</style>