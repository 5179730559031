<template>
  <div id="app">
    <transition name="component-fade">
    <router-view/>
    </transition>
  </div>
</template>

<script>
import Gun from 'gun/gun'
import AsyncLocalStorage from '@createnextapp/async-local-storage'
require('gun/sea');

export default {
  name: 'App',
  async mounted() {
    const wipeLocalStorage = await AsyncLocalStorage.getItem('wipe')

    if (!wipeLocalStorage) {
      localStorage.clear()
      await AsyncLocalStorage.setItem('wipe', 2021)
    }
    
    this.$store.dispatch('login')
  }
}
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

.hidden {
  display: none;}

body {
  font-family: 'Open Sans', sans-serif;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
