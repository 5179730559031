const formatNumberCharacter = (num: number) => {
	if(isNaN(num)) return num;

	if(num < 9999) {
		return num;
	}

	if(num < 1000000) {
		return Math.round(num/1000) + "K";
	}
	if( num < 10000000) {
		return (num/1000000).toFixed(2) + "M";
	}

	if(num < 1000000000) {
		return Math.round((num/1000000)) + "M";
	}

	if(num < 1000000000000) {
		return Math.round((num/1000000000)) + "B";
	}

		if(num < 1000000000000000) {
		return Math.round((num/1000000000000)) + "T";
	}

	return "Too Much Money";
}

export default formatNumberCharacter