import firebase from 'firebase'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyAZ7I1suydxrujhrcDwMiPL1lvMcqhIaIs",
  authDomain: "wheretospend-0121.firebaseapp.com",
  databaseURL: "http://wheretospend-0121-default-rtdb.firebaseio.com",
  projectId: "wheretospend-0121",
  storageBucket: "wheretospend-0121.appspot.com",
  messagingSenderId: "103573205245",
  appId: "1:103573205245:web:46b13f7aa1b8c956020e94",
  measurementId: "G-JV392Y656F"
}

if (location.hostname === 'localhost' || location.hostname === '192.168.86.103') {
  firebaseConfig.databaseURL = 'http://localhost:9000/?ns=wheretospend-0121'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

// // utils
// const db = firebase.database()
const dbStore = firebase.firestore()
const auth = firebase.auth()

if (location.hostname === 'localhost' || location.hostname === '192.168.86.103') {
  dbStore.useEmulator('localhost', 8000);
  auth.useEmulator('http://localhost:9099');

}

export {
  // db,
  dbStore,
  auth
}