<template>
  <div class="flow relative" v-if="populated">

		<div class="chat p-4">
			<!-- Home Intro / conditonal on if user has been to site before -->
			<Intro/>

			<!-- Chat Intro or Return / conditional on if user has been to site befoer -->
			<!-- <pre>{{budgets}}</pre> -->
			<!-- Increase Budget Intro or Prepopulate -->
			<div v-if="chatState.increaseBudget.started">
				<ChatBubbles img>
					<template v-slot:talk>
						<div>Of the 17 budgets, where should Congress spend <strong class="text-green-500">more</strong>?</div>
					</template>
					<template v-slot:image>
						<img src="https://media.giphy.com/media/FxZOEe9pA9xoMt1I2Z/giphy.gif" />
					</template>
				</ChatBubbles>
				
				<PillSelect v-for="(budget, index) in Object.keys(budgets).filter(budget => budgets[budget].selected !== 'decrease')" 
					v-bind:key="index + '_increase'"
					borderColor="green"
					:budget="budgets[budget]"
					:selectPill="() => selectPill(budgets[budget], 'increase', budgets)">
				</PillSelect>
				<div class="flex w-full">
					<div class="w-full py-2 px-4 border-2 border-gray-300 rounded-md text-center mr-4"
						@click="() => { 
							triggerSlider(true);
							triggerHelper('increase', true);
							displayNextChat(chatState, chatState.increaseBudget);
						}">Descriptions</div>
					<div class="w-full py-2 px-4 border-2 border-gray-300 rounded-md text-center"
						@click="() => { 
							displayNextChat(chatState, chatState.increaseBudget);
							scrollToId(chatState.increaseBudget.goTo[0]); 
						}">Next</div>
				</div>
			</div>

			<!-- Decrease Budget Intro or Prepopulate -->
			<div :id="chatState.increaseBudget.goTo[0]" class="offset-scroll mb-16" />
			<div v-if="chatState.decreaseBudget.started" class="mb-8">
				<ChatBubbles>
					<template v-slot:talk>
						<div>Where should Congress spend <strong class="text-red-500">less</strong>?</div> 
					</template>
				</ChatBubbles>

				<PillSelect v-for="(budget, index) in Object.keys(budgets).filter(budget => budgets[budget].selected !== 'increase')" 
					v-bind:key="index + '_decrease'" 
					borderColor="red"
					:budget="budgets[budget]"
					:selectPill="() => selectPill(budgets[budget], 'decrease', budgets)">
				</PillSelect>
				<div class="flex w-full">
					<div class="w-full py-2 px-4 border-2 border-gray-300 rounded-md text-center mr-4"
						@click="() => { 
							triggerSlider(true);
							triggerHelper('decrease', true);
						}">Descriptions</div>
					<div class="w-full py-2 px-4 border-2 border-gray-300 rounded-md text-center"
						@click="() => {
							displayNextChat(chatState, chatState.decreaseBudget);
							scrollToId(chatState.decreaseBudget.goTo[0]);
						}">Next</div>
				</div>
			</div>

			<!-- Other Budget Additions? -->

			<!-- Show Compare Information -->
			<div :id="chatState.decreaseBudget.goTo[0]" class="offset-scroll" />
			<div class="show-compare" v-if="chatState.compareBudget.started">
				<ChatBubbles>
					<template v-slot:talk>
						<div class="mb-2">All Set! See how your choices compare.</div>
						<div class="share-button rounded-md p-2 text-center" @click="() => {
							triggerSlider(true);
							triggerShowCompare(true)
						}">Show Compare</div>
					</template>
				</ChatBubbles>
			</div>

			<!-- Show Options To Share -->
			<div v-if="chatState.shareBudget.started">
				<ChatBubbles>
					<template v-slot:talk>
						<Share :congressAction="() => {
								displayNextChat(chatState, chatState.shareBudget.shareActions.congress);
								triggerSlider(true);
								triggerAddress(true);
							}" 
							:shareAction="() => navigatorShare()" />
					</template>
				</ChatBubbles>
			</div>

			<!-- Address -->
			<div :id="chatState.shareBudget.shareActions.congress.goTo[0]" class="offset-scroll mb-8" />
			<div v-if="chatState.addressBudget.started && location.length">
				<ChatBubbles myPost>
					<template v-slot:talk>
						<div class="font-semibold">Address</div>
						<div>{{location}}</div>
					</template>
				</ChatBubbles>
			</div>

			<!-- Congressmen -->
			<div class="congress-people relative w-full block user-message-container mb-4" v-if="chatState.congressBudget.started || location">
				<ChatBubbles>
					<template v-slot:talk>
						<a v-for="(congressMen, index) in congress.officials" 
							v-bind:key="`${index}-congressman`" 
							class="block share-button rounded-md p-2 mb-2 twitter-mention-button"
							:href="`https://twitter.com/intent/tweet?text=I wish Congress would spend my tax dollars like this → https://wheretospend.org/spend/${userId} @${congressMen.channels.filter(social => (social.type === 'Twitter'))[0].id}&hashtags=wheretospend&&ref_src=twsrc%5Etfw`" data-size="large" data-show-count="false">
							<div class="flex" v-if="congressMen.channels.filter(social => social.type === 'Twitter')">
								<div class="circle mr-2" :style="{ 'background-image': `url(${congressMen.photoUrl}), url(${UserPlaceholder})` }"></div>
								<div>
									<strong>
										Tweet @{{congressMen.channels.filter(social => (social.type === 'Twitter'))[0].id}}
									</strong>
									<br />
									{{congressMen.party}}
								</div>
							</div>
						</a>
						<a class="block w-full py-2 px-4 bg-blue-400 text-center rounded text-white font-semibold twitter-mention-button" 
							:href="`https://twitter.com/intent/tweet?text=I wish Congress would spend my tax dollars like this → https://wheretospend.org/spend/${userId} ${socialTwitter(congress).join(' ')}&hashtags=wheretospend&ref_src=twsrc%5Etfw`"
							data-size="large" 
							data-show-count="false">
								Tweet All
						</a>
					</template>
				</ChatBubbles>
			</div>
		</div>
		
		<div class="slide-up shadow-md" 
			:class="{ 
				'animate__animated animate__fadeOutDownBig': !openSlider, 
				'animate__animated animate__fadeInUpBig': openSlider 
			}">
			<div class="p-4 pt-4 pb-0" @click="() => { 
					triggerSlider(false);
					triggerShowCompare(false);
					triggerAddress(false);
					triggerHelper('increase', false);
					triggerHelper('decrease', false);
				}">
				<img class="down-arrow" :src="downArrow" />
			</div>
			<div v-if="openHelper['increase']" class="absolute overflow-scroll h-full">
				<ul class="mb-10 px-4" v-for="budget in Object.keys(budgets).filter(budget => budgets[budget].selected !== 'decrease')" 
					v-bind:key="budget">
					<li class="font-semibold capitalize mb-2">{{budgets[budget].title}}</li>
					<li v-for="(bullet, index) in budgets[budget].bullets" 
						v-bind:key="`help-bullet-increase-${index}`" 
						class="help-underline mb-2 text-sm">
						{{bullet.text}}
					</li>
					<li class="text-center border px-4 py-2 rounded-md font-semibold uppercase" 
						:class="{ 'bg-green-200': (budgets[budget].selected === 'increase') }"
						v-if="budgets[budget]" 
						@click="() => selectPill(budgets[budget], 'increase', budgets)">
						{{ (budgets[budget].selected !== 'increase') ? 'Select' : 'Unselect' }}
					</li>
				</ul>
			</div>
			<div v-if="openHelper['decrease']" class="absolute overflow-scroll h-full">
				<ul class="mb-10 px-4" v-for="budget in Object.keys(budgets).filter(budget => budgets[budget].selected !== 'increase')" 
					v-bind:key="budget">
					<li class="font-semibold capitalize mb-2">{{budgets[budget].title}}</li>
					<li v-for="(bullet, index) in budgets[budget].bullets" 
						v-bind:key="`help-bullet-increase-${index}`" 
						class="help-underline mb-2 text-sm">
						{{bullet.text}}
					</li>
					<li class="text-center border px-4 py-2 rounded-md font-semibold uppercase" 
						:class="{ 'bg-red-200': (budgets[budget].selected === 'decrease') }" 
						v-if="budgets[budget]" 
						@click="() => selectPill(budgets[budget], 'decrease', budgets)">
						{{ (budgets[budget].selected !== 'decrease') ? 'Select' : 'Unselected' }}
					</li>
				</ul>
			</div>
			<div v-if="openShowCompare">
				<ShowCompare />
			</div>
			<div v-if="openAddress">
				<div class="your-address">
					<ChatBubbles class="p-4 pb-0">
						<template v-slot:talk>
							Great! Add your address to find your Congressional reps
						</template>
					</ChatBubbles>
					<div class='flex p-4 items-center'>
						<input v-model="location" placeholder="Mailing Address" type="text" class="location-input p-2 mr-5 relative w-full" autocomplete="off" list="autocompleteOff" />
						<div @click="() => { location = '' }">
							<svg class="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 120" x="0px" y="0px">
								<title>Artboard 17</title>
								<g>
									<path d="M12.59,80.59,45.17,48,12.59,15.41a2,2,0,0,1,2.83-2.83L48,45.17,80.59,12.59a2,2,0,0,1,2.83,2.83L50.83,48,83.41,80.59a2,2,0,1,1-2.83,2.83L48,50.83,15.41,83.41a2,2,0,0,1-2.83-2.83Z"/>
								</g>
							</svg>
						</div>
					</div>
					
					<div class="search-address-list z-10 left-0 right-0 top-0 bg-white rounded m-4 shadow-md" v-if="searchResults.length">
						<div class="font-semibold text-lg p-4">Search Mailing Address</div>
						<ul class="">
							<li class="select-address border-t p-4" 
								v-for="(result, i) in searchResults" 
								:key="i" 
								@click="() => { 
									addAddress(result);
									twitterCongressMen(result);
									triggerSlider(false);
									triggerAddress(false);
									scrollToId(chatState.shareBudget.shareActions.congress.goTo[0]);
								}" >
								{{ result }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import 'animate.css'
import { db, dbStore } from '@/firebase'
// assets
import WeirdBubble from '@/assets/WeirdBubble.svg'
import downArrow from '@/assets/downarrow.svg'
import UserPlaceholder from '@/assets/user-placeholder.png'
// components
import PillSelect from '@/components/flow/PillSelect'
import Share from '@/components/flow/Share'
import ShowCompare from '@/components/flow/ShowCompare'
import ChatBubbles from '@/components/flow/ChatBubbles'
// views
import Intro from '@/views/Intro'
// services
import { getTwitterAccounts } from '@/services/services.js'
// utils
import getUserId from '@/utils/getUserId'
import setPreSelectedBudgets from '@/utils/setPreSelectedBudgets'
//mock-data
import { options } from '@/mock-data/options'

export default {
  name: 'Flow',
	components: { 
		Intro,
		PillSelect,
		Share,
		ShowCompare,
		ChatBubbles
		// SendSvg
	},
	watch: {
		location: function(newValue) {
			if (newValue) {
				this.service.getPlacePredictions({
					input: this.location,
					types: ['address']
				}, this.displaySuggestions)
			}
		},
		address: function(val) {
			this.$store.dispatch('storeZipCode', val)
		},
		openShowCompare: function(val) {
			this.getDBResults()
		}
	},
  data() {
    return {
			congress: {},
			populated: true,
			WeirdBubble,
			downArrow,
			options,
			openSlider: false,
			openShowCompare: false,
			openAddress: false,
			openHelper: {
				increase: false,
				decrease: false
			},
			searchResults: [],
			service: null,
			address: '',
			location: '',
			UserPlaceholder
    }
  },
	metaInfo() {
		return {
			script: [{
				src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1A13flwhI8RlgFxSfzn7x6YH8ie0Cxqo&libraries=places`,
				async: true,
				defer: true,
				callback: () => this.MapsInit() // will declare it in methods
			}]
		}
	},
	async mounted() {
		this.getDBResults()
	},
	computed: {
		userId() {
			return this.$store.getters.userId
		},
		chatState() {
			return this.$store.getters.chat
		},
		budgets() {
			return this.$store.getters.budgets
		}
	},
	methods: {
		getTwitterAccounts,
		async getDBResults() {
			const userId = await getUserId()
			const requestIncreaseTags = await dbStore.collection('increaseTags').doc(userId).get()
			const requestDecreaseTags = await dbStore.collection('decreaseTags').doc(userId).get()
			const requestAddress = await dbStore.collection('users').doc(userId).get()

			setPreSelectedBudgets(requestIncreaseTags, 'increase', this.budgets, options, this.$store)
			setPreSelectedBudgets(requestDecreaseTags, 'decrease', this.budgets, options, this.$store)
			this.location = (requestAddress.exists && requestAddress.data().address) ? requestAddress.data().address : ''
			if (this.location) this.twitterCongressMen(this.location) 

			this.chatState.increaseBudget.completed = (requestIncreaseTags.exists) ? true : false
			this.chatState.decreaseBudget.started = this.chatState.decreaseBudget.completed = (requestDecreaseTags.exists) ? true : false
			this.chatState.compareBudget.started = (requestDecreaseTags.exists) ? true : false
			this.chatState.shareBudget.started = (requestDecreaseTags.exists) ? true : false
			this.chatState.addressBudget.started = (requestAddress.exists && requestAddress.data().address) ? true : false
			this.chatState.congressBudget.started = (requestAddress.exists && requestAddress.data().address) ? true : false
		},
		async twitterCongressMen(address) {
			const userId = await getUserId()
			this.$store.commit('userId', userId)
			const representativesRequest = await this.getTwitterAccounts(address)
			const representativesResponse = await representativesRequest.json()

			this.congress = representativesResponse
		},
		MapsInit () {
			this.service = new window.google.maps.places.AutocompleteService()
		},
		addAddress(selectedLocation) {
			this.address = this.location = selectedLocation
		},
		displayNextChat(chatState, currentChat) {
			currentChat.complete = true
			currentChat.goTo.forEach(to => {
				chatState[to].started = true
			})
		},
		incrementBudgetItem(item, increaseOrDecrease, count, budgets) {
			if (!item && !increaseOrDecrease) return false
			this.$store.dispatch('budgetItem', { item, typeFlag: increaseOrDecrease, count, budgets })
		},
		subtractBudgetItem(item, increaseOrDecrease, count, budgets) {
			this.$store.dispatch('subtractBudgetItem', { item, typeFlag: increaseOrDecrease, count, budgets })
		},
		selectPill(budget, increaseOrDecrease, budgets) {
			if (!increaseOrDecrease) return false
			
			let listSelectedBudgetItems = []
			
			budget.selected = (budget.selected === false) ? increaseOrDecrease : false
			listSelectedBudgetItems = this.getListOfSelectedBudgetItems(budgets, increaseOrDecrease)
			
			if (budget.selected === false) {
				// if you remove item - subtract count
				this.subtractBudgetItem(budget.cardid, increaseOrDecrease, -1, listSelectedBudgetItems)
				return false
			}

			// if you select item - add count
			this.incrementBudgetItem(budget.cardid, increaseOrDecrease, 1, listSelectedBudgetItems)
		},
		getListOfSelectedBudgetItems(budgets, increaseOrDecrease) {
			return Object.keys(budgets)
				.filter(budget => {
					if (increaseOrDecrease && (options[budget].selected === increaseOrDecrease)) {
						return options[budget]
					}

					if (!increaseOrDecrease) {
						return options[budget]
					}
				})
		},
		triggerSlider(slider) {
			this.openSlider = slider
			document.body.style.overflow = (slider === true) ? 'hidden' : 'auto'
			// document.querySelector('.chat').style.height = (slider === true) ? '5vh' : 'auto'
			// document.querySelector('.chat').style.overflow = (slider === true) ? 'scroll' : 'auto'
		},
		triggerShowCompare(showCompare) {
			this.openShowCompare = showCompare
		},
		triggerAddress(address) {
			this.openAddress = address
		},
		triggerHelper(prop, helper) {
			this.openHelper[prop] = helper
		},
		navigatorShare() {
			if (navigator.share) {
				navigator.share({
					title: 'Where To Spend',
					text: 'See where I would like my tax dollars to go.',
					url: `https://wheretospend.org/spend/${this.$store.getters.userId}`,
				})
					.then(() => console.log('Successful share'))
					.catch((error) => console.log('Error sharing', error));
			} else {
				this.$router.push({ path: `/spend/${this.$store.getters.userId}`})
			}
		},
		socialTwitter(congress) {
			if (!congress && congress.officials) return []

			const twitterIds = []
			if (congress?.officials?.length) {
				congress.officials.map(official => official.channels.filter(social => {
					if (social.type === 'Twitter') twitterIds.push(`@${social.id}`)
				}))
				return twitterIds
			}
			return []
		},
		displaySuggestions (predictions, status) {
			if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
				this.searchResults = []
				return
			}
			this.searchResults = predictions.map(prediction => prediction.description)
		},
		scrollToId(id) {
			setTimeout(() => {
				const element = document.getElementById(id)

				element.scrollIntoView({ 
					behavior: 'smooth' 
				});
			}, 0)
		}
	}
}
</script>

<style lang="scss">
	.flow {
		// height: 100vh;
    width: 100%;
    max-width: 700px;
    // overflow-y: scroll;
    margin: 0 auto;
	}
	.firebase-emulator-warning {
		display: none !important;
	}
	.chat {
		transition: height 1s;
	}
	.slide-up {
		height: 95vh;
    background: #fff;
    position: fixed;
    top: 5vh;
    width: 100%;
		max-width: 700px;
    border-radius: 8px;
    border: 1px solid #aaa;
		box-shadow: 2px -2px 16px -5px rgba(0,0,0,0.75);
	}
	.down-arrow {
		height: 35px;
	}
	.close {
		height: 30px;
	}
	.congress-people {
		.circle {
			width: 45px;
			height: 45px;
			border-radius: 50%;
			background-size: cover;
		}
	}
	.your-address {
		overflow: scroll;
    height: 100vh;

		.location-input {
			border-radius: 25px;
			border: 1px solid #aaa;
		}
	}
	.share-button {
    background: #fff;

    &:active {
      background: #ccc;
    }
  }
	.help-underline {
		border-left: 4px solid #7eb643;
		padding-left: 4px;
	}
</style>