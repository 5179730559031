import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { auth } from '@/firebase'
import VModal from 'vue-js-modal'

Vue.config.productionTip = false
Vue.use(VModal)
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true
})

auth.onAuthStateChanged(user => {
  if (user) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')    
  } else {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')    
  }
})
