<template>
  <div>
    <div class="home v-2 relative" v-if="version === 1">
      <div class="flex">
				<div class="relative w-full"><Flow /></div>
				<div class="secondary-sidebar">
					<TheData hideNavBar="false"/>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Home from '@/views/Home'
import Flow from '@/views/Flow'
import TheData from '@/views/TheData'
import 'animate.css'
import WeirdBubble from '@/assets/WeirdBubble.svg'

export default {
  name: 'Desktop',
  components: {
		Flow,
		TheData
  },
  data() {
    return {
      version: 1,
      WeirdBubble
    };
  },
  mounted() {
    // this.$store.dispatch('getBudgetItems')
  },
  methods: {
    fullScreen() {
      const element = document.documentElement
      if(element.requestFullscreen) {
        element.requestFullscreen();
      } else if(element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if(element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if(element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  }
}
</script>

<style lang="scss">
  @import url('https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css');

	.secondary-sidebar {
		width: 40%;
		padding-top: 25px;
		padding-left: 35px;
		border-left: 1px solid #aaa;
	}

	.initial-sidebar {
		width: 40%;
		padding-top: 25px;
		padding-right: 35px;
		border-right: 1px solid #aaa;
	}

	.tab-bottom {
		width: calc(100% - 16% - 16%) !important;
		margin: 0 auto;

	}
  
	.home {
    height: 604px;
    width: 100%;
    margin: 0 auto;
    
    .center-me {
      display: grid;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 60px);
      
      .homepage {
        max-width: 350px;
      }
    }
  }

  .v-2 {
    .money-logo {
      width: 215px;
      text-align: center;

      img {
        max-width: 200px;
        width: 200px;
        height: 178px;
      }
      
      .icon {
        position: relative;
        z-index: 100;
      }
      
      .icon-alt {
        position: relative;
        z-index: 100;
        height: 80px;
        width: 80px;
      }
      
      .icon-alt-2 {
        position: relative;
        z-index: 100;
        height: 60px;
        width: 60px;
      }

      .icon-alt-3 {
        position: relative;
        z-index: 100;
        height: 70px;
        width: 70px;
      }
      
      .circle-svg {
        border-radius: 50%;
        padding: 10px;
      }
      
      .money-svg {
        top: -20px;
        left: -70px;
        animation: mover 2s infinite  alternate;
      }
      
      .capital-svg {
        top: 70px;
        left: 25px;
        animation: mover 2s infinite  alternate;
        animation-delay: 1s;
      }
      
      .compare-svg {
        top: 30px;
        left: 70px;
        animation: mover 2s infinite  alternate;
        animation-delay: 0.5s;
      }
    }

    @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
    }
    @keyframes mover {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }

    
    .cta {
      bottom: 35px;
      margin-bottom: 15px;
      .cta-start {
        border: 1px solid #bbb;
        width: 200px;
        border-radius: 25px;
      }
    }
  }
</style>