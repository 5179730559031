export const options = {
  medicare: {
    cardid: "medicare",
    title: "Medicare",
    titleShort: "Medicare",
    bullets: [
      { text: "Health insurance and benefits" },
      { text: "Serving senior citizens and people with disabilities" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Medicare_(United_States)",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  sossec: {
    cardid: "sossec",
    title: "Social Security",
    titleShort: "Social Security",
    bullets: [
      { text: "Social security benefit benefits" },
      {
        text: "Serving retired or disabled workers and/or their family members"
      }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Social_Security_(United_States)",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  natdef: {
    cardid: "natdef",
    title: "National Defense",
    titleShort: "National Defense",
    bullets: [
      {
        text: "Health, benefits, and pay for military personnel and dependents"
      },
      { text: "Department of Defense (DoD)" },
      { text: "Weapons research and development" },
      { text: "Military construction" },
      {
        text:
          "Nuclear-weapons activities (Dept of Energy, National Nuclear Security Admin)"
      },
      { text: "Selective Service Agency" },
      { text: "Some Coast Guard and FBI" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Military_budget_of_the_United_States",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  health: {
    cardid: "health",
    title: "Health",
    titleShort: "Health",
    bullets: [
      { text: "Medicaid" },
      { text: "Children’s Health Insurance Program (CHIP)" },
      { text: "Affordable Care Act (ACA)" },
      { text: "National Institutes of Health (NIH)" },
      { text: "Centers for Disease Control and Prevention (CDC)" },
      { text: "Food and Drug Administration (FDA)" },
      { text: "Federal and retirees' health benefits" },
      { text: "Anti-bioterrorism activities" },
      { text: "National biomedical research" },
      { text: "Workplace protection and training" },
      { text: "Health services for the under-served" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  incsec: {
    cardid: "incsec",
    title: "Income Security",
    titleShort: "Income Security",
    bullets: [
      { text: "Unemployment" },
      { text: "School meals" },
      { text: "Supplemental Nutrition Assistance Program (SNAP)" },
      { text: "Supplemental Security Income" },
      { text: "Temporary Assistance to Needy Families" },
      { text: "Earned Income Tax Credit" },
      { text: "Child Tax Credit" },
      { text: "Federal employee retirement and disability" },
      { text: "Foster child care" },
      { text: "Programs to aid abused and neglected children" },
      { text: "Low-income assistance" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  gengov: {
    cardid: "gengov",
    title: "General Government",
    titleShort: "General Government",
    bullets: [
      { text: "White House and the Executive Office of the President" },
      { text: "The legislative branch (Congress)" },
      {
        text:
          "Federal personnel management, fiscal operations, records, and property control"
      }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  vetbenssvc: {
    cardid: "vetbenssvc",
    title: "Veterans Benefits and Services",
    titleShort: "Veterans",
    bullets: [
      { text: "Department of Veterans Affairs (VA)" },
      {
        text:
          "Veterans' medical care, compensation and pensions, education and rehabilitation benefits, and housing programs"
      },
      {
        text: "Department of Labor's Veterans' Employment and Training Service"
      },
      { text: "U.S. Court of Appeals for Veterans Claims" },
      { text: "American Battle Monuments Commission" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  intafr: {
    cardid: "intafr",
    title: "International Affairs",
    titleShort: "International Affairs",
    bullets: [
      { text: "U.S. embassies and consulates" },
      { text: "Military assistance to allies" },
      {
        text: "Economic assistance to developing nations and democracies"
      },
      { text: "Promoting U.S. exports abroad" },
      { text: "Payments to international orgs" },
      { text: "Peacekeeping efforts" },
      { text: "Department of State" },
      { text: "Department of the Treasury" },
      { text: "Peace Corps" },
      { text: "U.S. Agency for International Development" },
      { text: "Millennium Challenge Corporation" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  etess: {
    cardid: "etess",
    title: "Education, Training, Employment, and Social Services",
    titleShort: "Education / Social Services",
    bullets: [
      { text: "Department of Education" },
      {
        text: "Department of Health and Human Services’ social service programs"
      },
      { text: "Title I and Pell grants for low-income students" },
      { text: "Department of Labor’s employment programs" },
      { text: "Library of Congress" },
      { text: "Corporation for Public Broadcasting" },
      { text: "Smithsonian" },
      { text: "National Gallery of Art" },
      { text: "National Endowments for Arts and Humanities" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  transportation: {
    cardid: "transportation",
    title: "Transportation",
    titleShort: "Transportation",
    bullets: [
      { text: "Department of Transportation" },
      { text: "Rebuilding highways, bridges, and roads" },
      { text: "Mass transit" },
      { text: "Coast Guard" },
      { text: "Federal Aviation Administration (FAA)" },
      { text: "Transportation Security Administration (TSA)" },
      { text: "Research at NASA" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  crd: {
    cardid: "crd",
    title: "Community and Regional Development",
    titleShort: "Community / Regional",
    bullets: [
      { text: "Disaster preparedness and relief" },
      { text: "Emergency Management Agency (FEMA)" },
      { text: "Mandatory funding for federal flood insurance" },
      { text: "Head Start" },
      { text: "Community Development Block Grant" },
      { text: "Department of Agriculture rural development programs" },
      { text: "Bureau of Indian Affairs" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  nre: {
    cardid: "nre",
    title: "Natural Resources and Environment",
    titleShort: "Environment",
    bullets: [
      { text: "Environmental Protection Agency (EPA)" },
      { text: "Recreation and wildlife areas" },
      {
        text:
          "Land, water, and mineral resources programs within the Departments of Agriculture, Commerce, Interior, and Transportation, and the Army Corps of Engineers"
      }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  adminjust: {
    cardid: "adminjust",
    title: "Administration of Justice",
    titleShort: "Administration of Justice",
    bullets: [
      { text: "Federal law enforcement" },
      { text: "Litigation, judicial, and correctional activities" },
      { text: "Federal Bureau of Investigation (FBI)" },
      { text: "Drug Enforcement Administration (DEA)" },
      {
        text: "Bureau of Alcohol, Tobacco, Firearms and Explosives (ATF)"
      },
      { text: "Border and Transportation Security" },
      { text: "Legal divisions within Department of Justice (DoJ)" },
      { text: "U.S. Citizenship and Immigration Services" },
      { text: "U.S. Immigration Enforcement (ICE)" },
      { text: "Parts of Department of Homeland Security (DHS)" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  chc: {
    cardid: "chc",
    title: "Commerce and Housing Credit",
    titleShort: "Commerce / Housing",
    bullets: [
      { text: "Federal Housing Administration (FHA)" },
      { text: "Fannie Mae, Freddie Mac, Rural housing programs" },
      { text: "Deposit insurance for banks and credit unions (FDIC)" },
      { text: "Department of Commerce" },
      { text: "U.S. Postal Service (USPS) net spending" },
      { text: "Securities and Exchange Commission (SEC)" },
      { text: "Federal Trade Commission (FTC)" },
      { text: "Federal Communications Commission (FCC)" },
      { text: "Small Business Administration" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  gsst: {
    cardid: "gsst",
    title: "General Science, Space, and Technology",
    titleShort: "Science / Technology",
    bullets: [
      { text: "National Science Foundation (NSF)" },
      { text: "Programs at NASA except for civil aviation programs" },
      {
        text: "General science programs at the Department of Energy (DOE)"
      }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  agriculture: {
    cardid: "agriculture",
    title: "Agriculture",
    titleShort: "Agriculture",
    bullets: [
      { text: "Farm income stabilization" },
      { text: "Agricultural research and education programs" },
      { text: "Department of Agriculture services" },
      { text: "Economics and statistics services" },
      { text: "Farm support and loan programs" },
      { text: "Meat and poultry inspection" },
      { text: "Commodity programs" },
      { text: "Crop insurance" },
      { text: "A portion of the international food aid program" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  },
  energy: {
    cardid: "energy",
    title: "Energy",
    titleShort: "Energy",
    bullets: [
      {
        text:
          "Renewable, civilian, and environmental energy programs in the Department of Energy"
      },
      {
        text:
          "Energy supply, conservation, policy, regulation, and emergency preparedness programs"
      },
      {
        text: "Rural Utilities Service in the Department of Agriculture"
      },
      { text: "Tennessee Valley Authority" },
      { text: "Federal Energy Regulatory Commission" },
      { text: "Nuclear Regulatory Commission" }
    ],
    reflinks: [
      "https://en.wikipedia.org/wiki/Government_spending_in_the_United_States#Discretionary_spending",
      "https://datalab.usaspending.gov/budget-function.html"
    ],
    selected: false,
    help: false
  }
};