export const calculatePercentageCount = (
	percentages: Array<any>, 
	countTags: Array<any>, 
	allCount: number, 
	incrementOrDecrement: string) => {
	const percentageCost: Array<any> = []

	return percentages.map(percentage => {
		percentage[`${incrementOrDecrement}Count`] = 0
		countTags.forEach(tag => {
			if (percentage.cardid === tag.title) {
				percentage[`${incrementOrDecrement}Count`] = (tag.data.count / allCount)
				return percentage
			}
		})
		return percentage
	})
}

export const calculateRelateToOthers = (
	numericalChangeDocs: Array<any>, 
	countTags: Array<any>) => {
	numericalChangeDocs.forEach(doc => {
		if (doc.id !== 'all') {
			countTags.push({
				title: doc.id,
				data: doc.data()
			})
		}
	})
	return countTags
}
