<template>
  <div class="my-spend">
    <div v-if="!noHeader" class="mb-12">
      <nav-bar-chat title="Sharing My Budget" route="/" />
    </div>

    <div v-if="tab === 'all' && allCompareSpending && allCompareSpending.results && allCompareSpending.results.length"
			:class="{ 'mt-20': !noHeader }">
      <div class="border rounded m-4">
        <div class="px-4 py-2 text-lg font-semibold bg-green-gradient">I chose to increase:</div>

        <div v-for="(spend, index) in percentages.results.filter(result => result.upvoted).sort((a, b) => { return a.pct - b.pct}).reverse()" 
          v-bind:key="`options-increase-${index}`">
          <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
            <div>
              <div class="text-lg">
                <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
              </div>
            </div>

            <div class="text-gray-600 mb-2">{{(spend.pct * 100).toFixed(0)}}% spent annually</div>
          </div>
        </div>
      </div>

      <div class="border rounded m-4" >
        <div class="px-4 py-2 text-lg font-semibold bg-red-gradient">I chose to decrease:</div>

        <div v-for="(spend, index) in percentages.results.filter(result => result.downvoted).sort((a, b) => { return a.pct - b.pct}).reverse()" v-bind:key="`options-increase-${index}`">
          <div class="compare-spend-box p-4 border-t" v-if="spend.titleShort">
            <div>
              <div class="text-lg">
                <span class="font-bold text-xl text-gray-800">{{spend.titleShort}}</span>
              </div>
            </div>

            <div class="text-gray-600 mb-2">{{(spend.pct * 100).toFixed(0)}}% spent annually</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarChat from '@/components/NavBarChat'
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { getSpendingCost } from '@/services/services'
import { dbStore } from '@/firebase'
import { options } from '@/mock-data/options'

import setAnnualBudgetCost from '@/utils/setAnnualBudgetCost'
import setVote from '@/utils/setVote'

export default {
  name: 'MySpend',
  components: {
    NavBarChat,
    // HorizontalScroll
  },
  props: {
    noHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tab: 'all',
      percentages: [],
      allCompareSpending: [],
      yourCompareSpending: [],
      increaseTags: [],
      decreaseTags: [],
      countIncrementTags: [],
      countDecrementTags: [],
    }
  },
  watch: {
    '$store.state.increaseTags': async function() {
      const userId = await AsyncLocalStorage.getItem('@userid')
      this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
      this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))
      this.currentSpending(true)
    },
    '$store.state.decreaseTags': async function() {
      const userId = await AsyncLocalStorage.getItem('@userid')
      this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
      this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))
      this.currentSpending(true)
    }
  },
  async mounted() {
    const userId = await AsyncLocalStorage.getItem('@userid')
    this.increaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-increaseTags`))
    this.decreaseTags = JSON.parse(await AsyncLocalStorage.getItem(`@${userId}-decreaseTags`))
    this.currentSpending()
    this.getSpendingCost()
  },
  methods: {
    switchTab(tab) {
      this.tab = tab
    },
    getSpendingCost,
    greaterThanZero(pct) {
      return (Math.round(Math.floor(pct)) < 0)
    },
    async currentSpending(wipe) {
      this.$store.dispatch('currentSpending')
      this.percentages = this.$store.getters.currentSpending
      
			const userId = (this.$route.params.id) ? this.$route.params.id : await AsyncLocalStorage.getItem('@userid')
      const optionsReduce = options;
      const totalBudget = this.percentages.total

      // pass in cardid, title, shortTitle for cross reference
      this.percentages.results = setAnnualBudgetCost(this.percentages.results, optionsReduce, totalBudget)

      this.increaseTags = await dbStore.collection('increaseTags').doc(userId).get() 
      this.decreaseTags = await dbStore.collection('decreaseTags').doc(userId).get()

			if (this.increaseTags.exists) {
				const resolvedIncreaseTags = this.increaseTags.data().increaseTags

				// request to get API
				if (this.increaseTags && Object.keys(this.increaseTags).length) {
					this.percentages.results = setVote(this.percentages.results, resolvedIncreaseTags, 'upvoted')
				}
			}

			if (this.decreaseTags.exists) {
				const resolvedDecreaseTags = this.decreaseTags.data().decreaseTags

				// request decrease and set to downvoted so display
				if (this.decreaseTags && Object.keys(this.decreaseTags).length) {
					this.percentages.results = setVote(this.percentages.results, resolvedDecreaseTags, 'downvoted')
				}
			}

      this.allCompareSpending = this.percentages
    }
    
  }
  
}
</script>

<style scoped lang="scss">
  .bg-green-gradient {
    background: linear-gradient(142deg, rgba(209,250,229,0.7) 51%, rgba(170,241,255,0.5) 100%);
  }
  .bg-red-gradient {
    background: linear-gradient(142deg, rgba(254,226,226, 0.7) 15%, rgba(199,161,184,0.5) 100%);
  }
  .select-tab {
    border-bottom: 2px solid black;
  }
  .profile-pic {
    border: 1px solid #bbb;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    svg {
      height: 35px;
      width: 35px;
    }
  }
  .dont-go-back {
    overscroll-behavior-x: none;
  }
  .my-spend {
    height: 100vh;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    .compare-spend-box {
      // border: 1px solid #bbb;
      // border-radius: 8px;
      // background: #f8f8f8;
    }
  }
  .pill {
    border-radius: 5px;
  }
  .progress-bar {
    height: 20px;
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 4px;
    background: #fff;
  }
  .percentage {
    height: 18px;
    border-radius: 4px;
  }
  .percentage-left {
    height: 18px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .percentage-right {
    height: 18px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
</style>