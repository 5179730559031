import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Desktop from '@/views/Desktop.vue'
import Chat from '@/views/Chat.vue'
import TheData from '@/views/TheData.vue'
// import MySpend from '@/views/MySpend.vue'
import MySpend from '@/components/flow/MySpend.vue'
import Flow from '@/views/Flow.vue'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    name: 'Home',
    component: Flow 
  },
  { 
    path: '/desktop', 
    name: 'Desktop',
    component: Desktop 
  },
  { 
    path: '/compare', 
    name: 'Compare',
    component: TheData 
  },
  { 
    path: '/spend/:id', 
    name: 'MySpend',
    component: MySpend,
    props: { noHeader: false }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
