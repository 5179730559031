import AsyncLocalStorage from '@createnextapp/async-local-storage'
// import { nanoid } from 'nanoid'
import { auth, db, dbStore } from '@/firebase'

const UserModule = {
  state: {
    userId: ''
  },
  getters: {
    userId: (state) => state.userId
  },
  mutations: {
    userId(state, userId) {
      state.userId = userId
    }
  },
  actions: {
    async login({ dispatch }) {
      const { user } = await auth.signInAnonymously()
      dispatch('setUser', user?.uid)
    },
    async setUser({ commit }, userid) {
      if (!userid) { return false }

      //check if user 
      const userId = await AsyncLocalStorage.getItem('@userid')
      if (userId) {
        commit('userId', userId)
      } 
      //if not generate userid
      else {
        await AsyncLocalStorage.setItem('@userid', userid)
        //await db.ref('users/' + userid).set({ id: userid });
        await dbStore.collection('users').doc(userid).set({ id: userid })
        commit('userId', userId)
        //store local storage
      }
    },
  },
}
export default UserModule