import AsyncLocalStorage from '@createnextapp/async-local-storage'
import capitalize from '@/utils/capitalize'

// @param typeFlag (String)- increase, decrease
const setBudgetFlag = async (userId: string, typeFlag: string, allBudgetFlag: boolean) => {
	const capitalizeTypeFlag = capitalize(typeFlag)

	if (!allBudgetFlag || allBudgetFlag === null) {
		await AsyncLocalStorage.setItem(`@${userId}-all${capitalizeTypeFlag}Flag`, true)
		await AsyncLocalStorage.getItem(`@${userId}-all${capitalizeTypeFlag}Flag`) 
		return typeFlag
	}

	return false
}

export default setBudgetFlag
