<template>
  <div class="pill-select border-2 rounded-md mb-2 mr-4 inline-block py-1 px-2" 
		:class="{ 
			'bg-green-200': budget.selected === 'increase', 
			'bg-red-200': budget.selected === 'decrease',
			'border-green-500': borderColor === 'green', 
			'border-red-500': borderColor === 'red' 
		}"> 
    <div @click="selectPill">
      <div>{{budget.titleShort}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PillSelect',
    props: {
			budget: {
				type: Object
			}, 
			selectPill: {
				type: Function
			},
			borderColor: {
				type: String
			}
		}
  }
</script>

<style scoped>
	.pill-select {

	}
	.increase-selected {

	}
</style>
