
const setAnnualBudgetCost = (percentages: Array<any>, optionsReduce: any, totalBudget: number) => {
  const annualBudgetCost: Array<any> = []

  percentages.forEach(percentage => {
    Object.keys(optionsReduce).forEach(option => {
      if (percentage.name === optionsReduce[option].title) {
        percentage.titleShort = optionsReduce[option].titleShort
        percentage.cardid = optionsReduce[option].cardid
        percentage.pct = percentage.amount/totalBudget
        annualBudgetCost.push(percentage)
      }
    })
  })

  return annualBudgetCost
}

export default setAnnualBudgetCost