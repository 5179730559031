import { getSpendingCost } from '@/services/services'
import { options } from '@/mock-data/options';
import { currentDefault } from '@/mock-data/comparedefaults';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { db, dbStore } from '@/firebase'
import firebase from 'firebase'

const BudgetModule = {
  state: {
    selectedBudget: [],
    options: options,
    currentSpending: currentDefault,
    tags: [],
    tagsDecrease: [],
    address: ''
  },
  getters: {
    options: (state) => state.options,
    currentSpending: (state) => state.currentSpending,
    tags: (state) => state.tags,
    tagsDecrease: (state) => state.tagsDecrease,
    address: (state) => state.address
  },
  mutations: {
    tags(state, tags) {
      state.tags = tags
    },
    tagsDecrease(state, tagsDecrease) {
      state.tagsDecrease = tagsDecrease
    },
    address(state, address) {
      state.address = address
    },
    currentSpending(state, spending) {
      state.currentSpending = spending
    }
  },
  actions: {
    async currentSpending({ commit }) {
      commit('currentSpending', getSpendingCost())
    },
    async incrementTag({ dispatch }, tag) { 
      //check for localstorage flag
      const userId = await AsyncLocalStorage.getItem('@userid')
      const allIncreaseFlag = await AsyncLocalStorage.getItem(`@${userId}-allIncreaseFlag`)
      
      if (!allIncreaseFlag || allIncreaseFlag === null) {
        await AsyncLocalStorage.setItem(`@${userId}-allIncreaseFlag`, true)
        const allIncreaseFlag = await AsyncLocalStorage.getItem(`@${userId}-allIncreaseFlag`)
        dispatch('allIncrease')
      }

      await dbStore.collection('increment').doc(tag.tag).update({ 
        count: firebase.firestore.FieldValue.increment(tag.count),
        time: firebase.firestore.FieldValue.serverTimestamp()
      }).catch(async (error) => {
        // The document probably doesn't exist.
        await dbStore.collection('increment').doc(tag.tag).set({ 
          count: 1, // revist
          time: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    async decrementTag({ dispatch }, tag) {
      //check for localstorage flag
      const userId = await AsyncLocalStorage.getItem('@userid')
      const allDecreaseFlag = await AsyncLocalStorage.getItem(`@${userId}-allDecreaseFlag`)
      
      if (!allDecreaseFlag || allDecreaseFlag === null) {
        await AsyncLocalStorage.setItem(`@${userId}-allDecreaseFlag`, true)
        const allDecreaseFlag = await AsyncLocalStorage.getItem(`@${userId}-allDecreaseFlag`)
        dispatch('allDecrease')
      }

      await dbStore.collection('decrement').doc(tag.tag).update({ 
        count: firebase.firestore.FieldValue.increment(tag.count),
        time: firebase.firestore.FieldValue.serverTimestamp()
      }).catch(async (error) => {
        // The document probably doesn't exist.
        await dbStore.collection('decrement').doc(tag.tag).set({ 
          count: 1, // revist
          time: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    async allIncrease({ commit }) {
      await dbStore.collection('increment').doc('all').update({ 
        count: firebase.firestore.FieldValue.increment(1),
        time: firebase.firestore.FieldValue.serverTimestamp()
      }).catch(async (error) => {
        // The document probably doesn't exist.
        await dbStore.collection('increment').doc('all').set({ 
          count: 1, // revist
          time: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    async allDecrease({ commit }) {
      await dbStore.collection('decrement').doc('all').update({ 
        count: firebase.firestore.FieldValue.increment(1),
        time: firebase.firestore.FieldValue.serverTimestamp()
      }).catch(async (error) => {
        // The document probably doesn't exist.
        await dbStore.collection('decrement').doc('all').set({ 
          count: 1, // revist
          time: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    async storeIncreaseTags({ commit }, tags) {
      const userId = await AsyncLocalStorage.getItem('@userid')
      await AsyncLocalStorage.setItem(`@${userId}-increaseTags`, JSON.stringify(Object.assign({}, tags)))
      const filterTags = []
      tags.map((tag) => {
        filterTags.push({ 
          cardid: tag.option.cardid, 
          title: tag.option.title, 
          titleShort: tag.option.titleShort 
        })
      })
      //await db.ref('users/' + userId + '/increaseTags').set(filterTags);
      await dbStore.collection('increaseTags').doc(userId).set({ increaseTags: filterTags})
      // await dbStore.collection('users').doc(userId).set({ increaseTags: filterTags})
      commit('tagsDecrease', tags)
    },
    async storeDecreaseTags({ commit }, tags) {
      const userId = await AsyncLocalStorage.getItem('@userid')
      await AsyncLocalStorage.setItem(`@${userId}-decreaseTags`, JSON.stringify(Object.assign({}, tags)))
      const filterTags = []
      tags.map((tag) => {
        filterTags.push({ 
          cardid: tag.option.cardid, 
          title: tag.option.title, 
          titleShort: tag.option.titleShort 
        })
      })
      //await db.ref('users/' + userId + '/decreaseTags').set(filterTags);
      await dbStore.collection('decreaseTags').doc(userId).set({ decreaseTags: filterTags})
      commit('tags', tags)
    },
    async storeZipCode({ commit }, address) {
      const userId = await AsyncLocalStorage.getItem('@userid')
      const storeAddress = await AsyncLocalStorage.setItem(`@${userId}-address`, address)
      //await db.ref('users/' + userId + '/address').set(address);
      await dbStore.collection('users').doc(userId).set({ address: address})
      commit('address', address)
    },
    async getBudgetItems() {
      const budgetItemsRequest = await fetch('https://www.wheretospend.org/data/moreLessCards.json')
      const budgetItemsResult = await budgetItemsRequest.json()
    }
  }
}
export default BudgetModule