const setPreSelectedBudgets = (selectedTags: any, increaseOrDecrease: string, budgets: any, options: any, store: any) => {
	if (selectedTags.exists) {
		const tags = selectedTags.data()

		if (tags[`${increaseOrDecrease}Tags`].length) { 
			for(let i = 0; i < tags[`${increaseOrDecrease}Tags`].length; i++) {
				const selectOption = options[tags[`${increaseOrDecrease}Tags`][i]]
				budgets[selectOption.cardid].selected = increaseOrDecrease
			}
			store.commit('budgets', budgets)
		}
	} else {
		console.log('no doc')
	}
}

export default setPreSelectedBudgets