<template>
  <div class="share">
		<a v-if="congressAction" @click="() => congressAction()" class="flex items-center share-button rounded-md mb-2">
      <img class="w-10" src="https://help.twitter.com/content/dam/help-twitter/brand/logo.png" /> 
      <div>Tweet Congress Person</div>
    </a>
		<div v-if="twitterAction" @click="() => twitterAction()">Tweet</div>
		<div v-if="shareAction" @click="() => shareAction()" class="flex items-center share-button rounded-md">
      <svg class="share w-10" 
        version="1.1" 
        x="0px" 
        y="0px" 
        viewBox="0 0 100 100" 
        enable-background="new 0 0 100 100" 
        xml:space="preserve">
        <g>
          <path fill="#000000" 
            d="M67.371,81.833h-33.16C29.132,81.833,25,77.7,25,72.621V39.462c0-5.08,4.132-9.211,9.211-9.211   c1.018,0,1.842,0.825,1.842,1.842c0,1.018-0.824,1.842-1.842,1.842c-3.047,0-5.526,2.479-5.526,5.528v33.159   c0,3.048,2.479,5.526,5.526,5.526h33.16c3.047,0,5.527-2.479,5.527-5.526V39.462c0-3.049-2.48-5.528-5.527-5.528   c-1.017,0-1.841-0.824-1.841-1.842c0-1.017,0.824-1.842,1.841-1.842c5.079,0,9.211,4.132,9.211,9.211v33.159   C76.582,77.7,72.45,81.833,67.371,81.833z"/>
        </g>
        <path fill="#000000" 
          d="M58.184,22.769l-5.96-5.96c-0.394-0.394-0.918-0.563-1.433-0.527c-0.514-0.036-1.039,0.134-1.431,0.527  l-5.96,5.96c-0.72,0.72-0.72,1.886,0,2.605c0.359,0.36,0.831,0.54,1.302,0.54c0.472,0,0.943-0.18,1.302-0.54l2.792-2.791v43.899  c0,1.018,0.825,1.843,1.843,1.843c1.016,0,1.841-0.825,1.841-1.843V22.277l3.098,3.097c0.359,0.36,0.83,0.54,1.303,0.54  c0.471,0,0.943-0.18,1.303-0.54C58.902,24.654,58.902,23.489,58.184,22.769z"/>
      </svg>
      <div>Share</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Share',
    props: {
      congressAction: {
        type: Function
      },
      twitterAction: {
        type: Function
      },
      shareAction: {
        type: Function
      }
    }
  }
</script>

<style scoped lang="scss">
  .share-button {
    background: #fff;

    &:active {
      background: #ccc;
    }
  }
</style>
